import React from "react"
import Layout from "../containers/Layout";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import SEO from "../components/genericComponents/SEO";

const PolicyPage = props => {
  const { t } = useTranslation()

  return (
    <Layout
      navBarProps={{
        navClass: "is--white",
      }}
      logoClassName={"filterClass"}
      path={props.path}
      optionsBooking={{ path: props.path }}
    >
       <SEO
        title={t('seo_policity_title')}
        desc={t('seo_policity_description')}
        pathname={"/politica-privacidad/"}
        article={false}
      />
      <section className="terms-content container-generic" data-scroll-section>
        <h1>{t("policty_title")}</h1>
        <p>{t('policty_content')}</p>
        <ol>
          <li>{t('policty_content_1')}</li>
          <li>{t('policty_content_2')}</li>
          <li>{t('policty_content_3')}</li>
          <li>{t('policty_content_4')}</li>
          <li>{t('policty_content_5')}</li>
          <li>{t('policty_content_6')}</li>
          <li>{t('policty_content_7')}</li>
          <li>{t('policty_content_8')}</li>
          <li>{t('policty_content_9')}</li>
        </ol>
      </section>
    </Layout>
  )
}

export default PolicyPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
